<template>
  <v-slide-x-transition>
    <MeinProfil />
  </v-slide-x-transition>
</template>

<script>
import MeinProfil from './User/MeinProfil/Uebersicht.vue'

export default {
  name: 'Profil',

  components: {
    MeinProfil,
  },
}
</script>
