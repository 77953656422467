<template>
  <v-container v-if="user.loggedIn">
    <v-row justify="center" class="text-center mt-4">
      <v-col cols="12" md="10" lg="9">
        <v-avatar size="75"><v-img :src="user.person.avatar"></v-img></v-avatar>
        <h2 class="mt-2">{{ user.data.vorname }} {{ user.data.nachname }}<font-awesome-icon class="ml-2" :icon="user.data.geschlecht == 'Männlich' ? 'fa-light fa-mars' : user.data.geschlecht == 'Weiblich' ? 'fa-light fa-venus' : 'fa-light fa-genderless'" /></h2>
        <h4 class="font-weight-regular">{{user.email}}</h4>
        <v-chip class="mt-3" :color="user.data.activated ? 'success' : 'error'" :to="!user.data.activated ? '/user/aktivierung' : undefined"><font-awesome-icon class="mr-2" :icon="user.data.activated ? 'fa-light fa-check-double' : 'fa-light fa-circle-exclamation'" />{{user.data.activated ? 'Verifiziert' : 'Nicht verifiziert'}} </v-chip>
      </v-col>
      <v-col cols="12" md="10" lg="9">
        <v-row justify="center">
          <v-col cols="12" md="10" lg="9">
            <v-select filled rounded multiple hide-details :items="sportarten.all" item-text="name" item-value="name" v-model="user.data.sportarten" label="Deine Sportarten" @change="save"></v-select>
          </v-col>
        </v-row>
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import logout from '../../../auth/auth'
import {
  doc,
  updateDoc
} from 'firebase/firestore'


export default {
  name: 'MeinProfil',
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',

    }),
  },
  methods: {
    logout() {
      logout.logout(this.auth)
    },
    async save(){
      const userdataRef = doc(this.db, "User", this.user.uid);
      await updateDoc(userdataRef, {
        sportarten: this.user.data.sportarten,
      });
    }
  },
}
</script>
